import React from 'react';
import {Link} from 'gatsby';
import img4 from '../../assets/images/blog/blog-img4.jpg';

const BlogCard = (props) => {
    return (
        <div className="blog-area ptb-100">
            <div className="container">
                <div className="row">
                    {props.posts.map(post => {
                        const {frontmatter} = post.node;
                        return (
                            <div className="col-lg-4 col-md-6">
                                <div className="single-blog-post bg-fffbf5">
                                    <div className="post-image">
                                        <Link to={frontmatter.slug}>
                                            <img src={frontmatter.bannerImage.childImageSharp.fluid.src} alt={frontmatter.title} />
                                        </Link>
                                    </div>

                                    <div className="post-content">
                                        <ul className="post-meta d-flex justify-content-between align-items-centerfixed">
                                            <li>
                                                {/*<div className="post-author d-flex align-items-center">*/}
                                                {/*    <img src={user1} className="rounded-circle" alt="image" />*/}
                                                {/*    <span>Alex Morgan</span>*/}
                                                {/*</div>*/}
                                            </li>
                                            <li>
                                                <i className='flaticon-calendar'></i> {frontmatter.updatedAt}
                                            </li>
                                        </ul>
                                        <h3>
                                            <Link to={frontmatter.slug}>
                                                {frontmatter.title}
                                            </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
};

export default BlogCard