import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import BlogCard from '../components/BlogContent/BlogCard';
import Footer from '../components/App/Footer';
export default class BlogList extends React.Component {
	render() {
		const posts = this.props.data.allMarkdownRemark.edges;
		return (
			<Layout>
				<Navbar />
				<PageBanner
					pageTitle="Blog"
					homePageText="Home"
					homePageUrl="/"
					activePageText="ブログ"
				/>
				<BlogCard posts={posts}/>
				<Footer />
			</Layout>
			// <Layout>
			// 	{posts.map(({ node }) => {
			// 		const title = node.frontmatter.title || node.fields.slug
			// 		return <div key={node.fields.slug}>{title}</div>
			// 	})}
			// </Layout>

		)
	}
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fields: { 
          collection: { eq: "blog" }
         }
         frontmatter: {
                    draft: {eq: false}
         }
      }
      sort: { fields: [frontmatter___createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            createdAt(formatString: "YYYY-MM-DD")
			updatedAt(formatString: "YYYY-MM-DD")
            slug
            categories
            bannerImage {
				childImageSharp {
					fixed(width: 50, height: 50) {
					  ...GatsbyImageSharpFixed
					}
					fluid(maxWidth: 1000, quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
            }
          }
        }
      }
    }
  }
`;